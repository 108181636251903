import "../scss/main.scss";
import 'slick-carousel';
import Typewriter from 'typewriter-effect/dist/core';

$(function(){

    $('#up').on('click', function(){
        $('html, body').animate({scrollTop: 0}, "slow");
        return false;
    });

    $('#down').on('click', function(){
        $('html, body').animate({scrollTop: $('h1').offset().top - $('#masthead').innerHeight()*2}, "slow");
        return false;
    });

    $('.bt-search').on('click', function(){
        $('#search').toggleClass('opensearch');
    });

    actionsScroll();
    resize();
    $(window).on('scroll', function(){    
        actionsScroll();
    }).on('resize', function(){
        resize();
    });

    // INIT ALL FUNCTIONS
    imgBack();
    sliders();
    if( $('.accordeon').length != 0) { accordeon(); }
    if( $('.tabs').length != 0){ onglets(); }
    animationScroll();
    if( $('.intro--title').length != 0) { typewrited(); }

    menuClic();
});

function resize(){

}

function actionsScroll(){
    $('.site-search').removeClass('active');

    var scrolled = $(window).scrollTop();

    if(scrolled > 0 ){
        $('body').addClass('scrolling'); 
    } else {
        $('body').removeClass('scrolling');
    }

    $('#btNav').removeClass('open');
    $('.col--navigation').removeClass('open');
    $('#nav-main > li.menu-item-has-children').removeClass('active').children('ul.sub-menu').slideUp();
}

function imgBack(){
    $('.backImg').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        if (urlImgCurrent && urlImgCurrent != undefined && $(this).children('img').length) {
            $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
            $(this).find('img').remove();		
        }	
    });
}

function sliders(){
    $('.bloc--carrousel').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 2500,
        respondTo: 'slider',
        pauseOnFocus: false,
        pauseOnHover: false,
        responsive: true,
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerPadding: '18px',
        prevArrow: '<i class="fa-sharp fa-light fa-chevron-left"></i>',
        nextArrow: '<i class="fa-sharp fa-light fa-chevron-right"></i>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    });

    $('.bloc--diaporama').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        fade: true,
        arrows: false,
        dots: false,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<i class="fa-sharp fa-light fa-chevron-left"></i>',
        nextArrow: '<i class="fa-sharp fa-light fa-chevron-right"></i>',
    });

    $('.bloc--slider').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: false,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<div class="slick-prev"><i class="fa-sharp fa-light fa-chevron-left"></i></div>',
        nextArrow: '<div class="slick-next"><i class="fa-sharp fa-light fa-chevron-right"></i></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    // $('.galerie--view').slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     fade: true,
    //     asNavFor: '.galerie--nav'
    //   });
    //   $('.galerie--nav').slick({
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     asNavFor: '.galerie--view',
    //     dots: false,
    //     centerMode: true,
    //     centerPadding: '18px',
    //     infinite: true,
    //     focusOnSelect: true,
    //     prevArrow: '<div class="slick-prev"><i class="fa-sharp fa-light fa-chevron-left"></i></div>',
    //     nextArrow: '<div class="slick-next"><i class="fa-sharp fa-light fa-chevron-right"></i></div>',
    //     responsive: [
    //         {
    //             breakpoint: 800,
    //             settings: {
    //                 slidesToShow: 2
    //             }
    //         },
    //     ]
    //   });
}

function onglets(){
    var hauteurMax = 0;
    $('.tab-content').each(function(){
        if( $(this).innerHeight() >= hauteurMax ){
            hauteurMax = $(this).innerHeight();
            $('.tab-content').css('min-height', hauteurMax);
        }
    });

    $('.tabs .tab').on('click', function(e){
        e.preventDefault();

        if( !$(this).parent('li').hasClass('active') ){
            var tab = $(this).attr('data');

            /* On suprime la class active de tous les contenus */
            $('.tab-content').removeClass('active');
            $('.tab-content').hide().delay( 200 );

            /* On supprime la classe pour tous les liens */
            $('.tabs li').removeClass('active');

            /* On ajoute la classe active à l'onglet qui doit être visible */
            $(tab).addClass('active');
            $(tab).show();

            /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
            $(this).parent('li').addClass('active');

            return false;
        }
    });
}

function accordeon(){
    $('.bloc--accordeon .accordeon--content').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".accordeon .accordeon--title").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".accordeon--content:visible").length != 0) {
            $(this).next(".accordeon--content").slideUp("normal");
            $(this).removeClass('open');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".accordeon .accordeon--content").slideUp("normal");
            $(".accordeon .accordeon--title").removeClass('open');
            $(this).next(".accordeon--content").slideDown("normal");
            $(this).addClass('open');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
    scrollAnimFade.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });

    if( $('.title--anim').length != 0) {
        let scrollAnimTitle = gsap.utils.toArray('.title--anim');
        scrollAnimTitle.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-top').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 95%",
                    end: "top 80%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-enter').length != 0) {
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }
}

function typewrited(){
    var writeText = $('.intro--title').text();
    new Typewriter('.intro--title', {
        strings : writeText,
        autoStart: true,
        loop: true,
    });
}

function menuClic(){
    $('#btNav').on('click', function(){
        $(this).toggleClass('open');
        $('.col--navigation').toggleClass('open');
        $('#nav-main > li.menu-item-has-children').removeClass('active').children('ul.sub-menu').slideUp();
    });

    $('#nav-main > li.menu-item-has-children').each(function(){
        var parent = $(this);
        var brothers = $(this).siblings('.menu-item-has-children');

        $(this).children("a").on('click', function(event){
            event.preventDefault();
            brothers.removeClass('active').children('ul.sub-menu').slideUp();
            parent.toggleClass('active').children('ul.sub-menu').slideToggle();
        });
    });
}